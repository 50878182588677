import React from 'react';
import { Layout } from '../components/layout';
import { Hero } from '@/components/layout/header/hero';
import { StaticImage } from 'gatsby-plugin-image';

export default function PagaLaSosta() {
  const pageTitle = 'Paga la sosta';

  const Banner = () => (
    <StaticImage
      src="../images/banners/paga-la-sosta.png"
      alt={pageTitle}
      quality={100}
    />
  );

  const Left = () => (
    <StaticImage
      src="../images/other/paga-la-sosta.png"
      quality={100}
      alt="paga-la-sosta"
    />
  );

  const LogoSize = 60;

  const Right = () => (
    <div>
      <div>
        <h2 className="h3 text-primary font-rubik">GPS App</h2>
        <p className="text-secondary font-oxygen mt-3 mb-5">
          Con la nostra app hai la possibilità di prenotare e pagare il
          parcheggio direttamente dal tuo smartphone, basterà creare un account
          collegato ad uno dei sistemi di pagamento con carte di credito.
        </p>
      </div>
      <div>
        <h2 className="h3 text-primary font-rubik">
          Scarica anche le nostre APP partner
        </h2>
        <div className="row">
          <div className="col-auto">
            <a href="https://flowbird.it/" target="_blank">
              <StaticImage
                height={LogoSize}
                src="../images/logos/logo-flowbird.png"
                quality={100}
                alt="flowbird"
              />
            </a>
          </div>
          <div className="col-auto">
            <a href="https://easyparkitalia.it/it/" target="_blank">
              <StaticImage
                height={LogoSize}
                src="../images/logos/logo-easypark.png"
                quality={100}
                alt="easypark"
              />
            </a>
          </div>
          <div className="col-auto">
            <a
              href="https://www.telepass.com/it/privati/supporto/app"
              target="_blank"
            >
              <StaticImage
                height={LogoSize}
                src="../images/logos/logo-telepass.png"
                quality={100}
                alt="telepass"
              />
            </a>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-auto cursor-pointer">
            <a
              href="https://apps.apple.com/us/app/gps-app/id1641670005"
              target="_blank"
            >
              <StaticImage
                height={42}
                src="../images/logos/logo-apple_store.png"
                quality={100}
                alt="ios"
              />
            </a>
          </div>
          <div className="col-auto cursor-pointer">
            <a
              href="https://play.google.com/store/apps/details?id=it.gpsparking.gpsapp"
              target="_blank"
            >
              <StaticImage
                height={42}
                src="../images/logos/logo-google_play.png"
                quality={100}
                alt="google-play"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Layout title={pageTitle}>
      <Hero title={pageTitle} banner={<Banner />} />
      <div className="container mt-5 mb-5">
        <div className="row gx-5">
          <div className="col-12 col-lg-5">
            <Left />
          </div>
          <div className="col-12 col-lg-7 mt-3">
            <Right />
          </div>
        </div>
      </div>
    </Layout>
  );
}
